<template>
  <v-form ref="form" lazy-validation>
    <v-card elevation="0">
      <v-card-title> تغيير كلمة المرور </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="currentPassword"
              label="كلمة المرور الحالية"
              style="direction: ltr"
              required
              outlined
              :counter="50"
              :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showCurrentPassword ? 'text' : 'password'"
              @click:append="showCurrentPassword = !showCurrentPassword"
              :rules="currentPasswordRules"
              autocomplete="new-password"
              :disabled="isLoading || isChanged"
              :loading="isLoading"
              :error-messages="currentPasswordErrors"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="newPassword"
              label="كلمة المرور الجديدة"
              style="direction: ltr"
              required
              outlined
              :counter="50"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
              :rules="newPasswordRules"
              autocomplete="new-password"
              :disabled="isLoading || isChanged"
              :error-messages="newPasswordErrors"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid>
        <v-row class="justify-end">
          <v-col sm="12" md="4" lg="2">
            <v-btn
              :color="isChanged ? 'green' : 'primary'"
              dark
              large
              :loading="isLoading"
              @click="submit"
              block
            >
              <v-icon color="#fff">
                {{ isChanged ? 'mdi-repeat' : 'mdi-content-save-outline' }}
              </v-icon>
              {{ isChanged ? 'تم التغيير' : 'حفظ كلمة المرور' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      isLoading: false,
      isChanged: false,
      currentPassword: '',
      showCurrentPassword: false,
      newPassword: '',
      showNewPassword: false,
    };
  },
  mixins: [validationMixin],
  validations() {
    return {
      currentPassword: { required },
      newPassword: { required },
    };
  },
  computed: {
    currentPasswordErrors() {
      const errors = [];
      if (!this.$v.currentPassword.$dirty) return errors;
      !this.$v.currentPassword.required && errors.push('الحقل مطلوب');
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) return errors;
      !this.$v.newPassword.required && errors.push('الحقل مطلوب');
      return errors;
    },
    currentPasswordRules() {
      if (this.currentPassword.length == 0) return [];
      return [
        (v) =>
          /(?=.{6,})/.test(v) || 'يجب ان تكون كلمة المرور على الاقل 6 حروف',
      ];
    },
    newPasswordRules() {
      if (this.newPassword.length == 0) return [];
      return [
        (v) => /(?=.*[a-z])/.test(v) || 'abc..z ' + 'ادخل حروف صغيرة',
        (v) => /(?=.*[A-Z])/.test(v) || 'ABC..Z ' + 'ادخل حروف كبيرة',
        (v) => /(?=.*[0-9])/.test(v) || '123..0 ' + 'ادخل ارقام',
        (v) =>
          /(?=.*[@#$%^*&+=])/.test(v) || '@#$%^&+=* ' + 'ادخل علامات مميزة',
        (v) =>
          /(?=.{6,})/.test(v) || 'يجب ان تكون كلمة المرور على الاقل 6 حروف',
      ];
    },
  },
  methods: {
    isInvalid() {
      const isValidForm = this.$refs.form.validate();
      this.$v.$touch();
      const isInvalidValidators = this.$v.$invalid;
      if (!isValidForm || isInvalidValidators) return true;
      return false;
    },
    submit() {
      if (this.isChanged) {
        this.isChanged = false;
        return;
      }

      if (this.isInvalid()) {
        showSnackbarWarningMessage('لايمكن الحفظ');
        return;
      }

      const currentPassword = this.currentPassword;
      const newPassword = this.newPassword;

      const postData = {
        currentPassword,
        newPassword,
      };

      this.isLoading = true;
      const failMsg = 'حدث خطأ اثناء عملية حفظ البيانات';
      this.$http
        .post('account/my-profile/change-password', { ...postData })
        .then((response) => {
          showSnackbarSuccessMessage('تم تغيير كلمة المرور بنجاح');
          this.isChanged = true;
        })
        .catch((error) => {
          const { status, data } = error.response || {};
          if (status != 403) return showSnackbarFailMessage(failMsg);
          const { error: { message } = {} } = data || {};
          return showSnackbarFailMessage(message);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
